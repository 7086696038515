/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
/* App imports */
import TagList from '../../../components/tag-list'
import style from './heading.module.less'

const Heading = ({ title, tags, cover, coverTitle }) => (
  <div className={[style.header, 'post-heading'].join(' ')}>
    <div className={style.title}>
      <h1>{title}</h1>
      { tags && <TagList tags={tags} />}
    </div>
    {cover && (<div className={[style.cover, 'post-cover'].join(' ')}>
      <Img fluid={cover} title={coverTitle} alt={title} />
    </div>)}
  </div>
)

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  cover: PropTypes.any,
  coverTitle: PropTypes.string,
}

export default Heading
